<template>
  <div class="content content--watch">
    <iframe
      src="https://www.youtube.com/embed/4aPpujgrTRs"
      title="Hosana & Eric's Wedding"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "Watch",

  setup() {
  },
}
</script>

<style scoped>
.content--watch {
  height: 0;
  /* margin-bottom: 75px; */
  padding-bottom: 56.25%;
  padding-top: 25px;
  position: relative;
}
iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
